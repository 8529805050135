import NextButton from './NextButton'

export default ({ handleSubmit }) => {
  return (
    <>
      <iframe src='https://www.viatik.com/uy/bono?embedded=true' className='w-full h-screen' />
      <NextButton onSubmit={handleSubmit} />
    </>
  )
}
