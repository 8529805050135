import { ArrowForwardIos } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Button from '../../commons/Button'

export default ({ onSubmit, disabled, children }) => {
  return (
    <div className='flex fixed bottom-8 right-8 md:mx-auto md:w-full md:right-1/2 md:left-1/2'>
      <div className='flex items-center md:hidden gap-8'>
        {children}
        <IconButton
          onClick={onSubmit}
          size='large'
          disabled={disabled}
          style={{
            backgroundColor: disabled ? '#9ca3af' : '#d65944',
            boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)'
          }}
        >
          <ArrowForwardIos sx={{ color: 'white' }} />
        </IconButton>
      </div>
      <div className='hidden md:flex md:flex-col gap-8'>
        {children}
        <Button
          name={'Continuar'}
          onClick={onSubmit}
          disabled={disabled}
          style={{ fontSize: '18px', width: '130px' }}
        />
      </div>
    </div>
  )
}
