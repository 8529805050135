import { useEffect, useState } from 'react'
import { useAuthorizedFetch } from '../../hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { Mixpanel } from '../../Mixpanel'
import Modal from 'react-modal'
import { TextField, ThemeProvider, createTheme } from '@mui/material'
import {
  faFaceAngry,
  faFaceFrown,
  faFaceMeh,
  faLaughBeam,
  faSmileBeam
} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../commons/Button'
import useStyles from './styles'
import { uri } from '../../config/Api'
import { DIAS, MESES } from '../../config/App'

export default ({}) => {
  const { loading, response } = useAuthorizedFetch(`${uri}/users/reviews`)
  const { user } = useAuth0()
  const [usersToReview, setUsersToReview] = useState([])
  const [visibleIndex, setVisibleIndex] = useState(-1)

  useEffect(() => {
    if (response.ok) {
      response.json().then(data => {
        setUsersToReview(data)
        setVisibleIndex(0)
      })
    }
  }, [response])

  return (
    <>
      {!loading &&
        usersToReview &&
        usersToReview?.map((userToReview, index) => (
          <Review
            key={index}
            userToReview={userToReview}
            visibleIndex={visibleIndex}
            setVisibleIndex={setVisibleIndex}
            index={index}
            user={user}
          />
        ))}
    </>
  )
}

const Review = ({ userToReview, visibleIndex, setVisibleIndex, index, user }) => {
  const { styles } = useStyles()
  const [stars, setStars] = useState(0)
  const [comment, setComment] = useState('')
  const [postReviews, setPostReviews] = useState(false)

  const handleButtonPress = () => {
    Mixpanel.track('Review enviada', {
      Estrellas: stars,
      Comentario: comment,
      Fecha: new Date().toISOString(),
      Reviewer: user.sub,
      Usuario: userToReview.user_id
    })
    setPostReviews(true)
    setVisibleIndex(visibleIndex + 1)
  }

  return (
    <>
      <Modal isOpen={visibleIndex === index} style={styles.modal}>
        <div className='px-6 pt-[22px] flex flex-col justify-between h-full flex-grow lg:w-1/2'>
          <div>
            <div className='flex flex-col gap-[12px] mb-[30px]'>
              {userToReview.companion ? (
                <>
                  <p className='text-[18px] text-title font-semibold'>
                    ¡Esperamos que hayas tenido un buen viaje {formatDateTitle(userToReview.date)}!
                  </p>
                  <p className='text-[16px] text-subtitle font-semibold'>
                    Calificá a {userToReview.name}
                  </p>
                </>
              ) : (
                <>
                  <p className='text-[18px] text-title font-semibold'>
                    ¡Esperamos que hayas tenido un buen viaje!
                  </p>
                  <p className='text-[16px] text-subtitle font-semibold'>
                    Calificá tu viaje {formatDate(userToReview.date)} con {userToReview.name}
                  </p>
                </>
              )}
            </div>
            <div className='flex flex-col gap-[20px]'>
              <Stars value={stars} setValue={setStars} />
              <Comment comment={comment} setComment={setComment} />
            </div>
          </div>
          <Button
            disabled={!stars || comment.trim().length === 0}
            name={'Enviar'}
            onClick={() => handleButtonPress()}
            style={styles.button}
          />
        </div>
      </Modal>
      {postReviews && (
        <PostReview
          review={{ qualification: stars, text: comment, lobby_id: userToReview.lobby_id }}
          userId={userToReview.user_id}
        />
      )}
    </>
  )
}

const Stars = ({ value, setValue }) => {
  return (
    <div className='flex flex-row justify-between w-full mb-6'>
      {[1, 2, 3, 4, 5].map(number => (
        <Star
          key={number}
          selected={value === number}
          onClick={() => setValue(number)}
          number={number}
        />
      ))}
    </div>
  )
}

const Comment = ({ comment, setComment }) => {
  const theme = createTheme({
    typography: {
      fontFamily: {
        Poppins: 'Poppins, sans-serif'
      }
    },
    shape: {
      borderRadius: 20
    },
    // input
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            backgroundColor: '#f3f4f6',
            border: 'none'
          }
        }
      }
    }
  })

  return (
    <div className='flex flex-col gap-[12px] mb-[52px]'>
      <p className='text-[16px] text-title'>Dejale un comentario</p>
      <ThemeProvider theme={theme}>
        <TextField
          id='comment'
          multiline
          maxRows={4}
          onChange={evt => setComment(evt.target.value)}
          placeholder='Escribí tu comentario'
          minRows={4}
          value={comment}
          inputProps={{
            maxLength: 180
          }}
          autoFocus
        />
      </ThemeProvider>
    </div>
  )
}

const PostReview = ({ review, userId }) => {
  const date = new Date().toISOString()
  const { loading, response } = useAuthorizedFetch(`${uri}/users/reviews/${userId}`, {
    method: 'POST',
    body: JSON.stringify({
      ...review,
      date
    })
  })

  return <></>
}

const formatDate = dateString => {
  const date = Date.parse(dateString)
  const today = new Date().getTime()
  const diff = today - date
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days === 0) {
    return 'de hoy'
  } else if (days === 1) {
    return 'de ayer'
  } else if (days < 7) {
    return `del ${DIAS[new Date(dateString).getDay()]}`
  } else {
    return `del ${new Date(dateString).getDate() + ' de ' + MESES[new Date(dateString).getMonth()]}`
  }
}

const formatDateTitle = dateString => {
  const date = Date.parse(dateString)
  const today = new Date().getTime()
  const diff = today - date
  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  if (days === 0) {
    return 'hoy'
  } else if (days === 1) {
    return 'ayer'
  } else if (days < 7) {
    return `el ${DIAS[new Date(dateString).getDay()].trim()}`
  } else {
    return `el ${new Date(dateString).getDate() + ' de ' + MESES[new Date(dateString).getMonth()]}`
  }
}

const Star = ({ selected = false, onClick, number }) => {
  return (
    <div className='flex flex-col items-center'>
      <div
        className={`flex justify-center items-center rounded-[10px] w-[52px] h-[52px] ${
          selected ? 'bg-primary' : 'bg-light_gray'
        }`}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={icon(number)}
          className={`text-[24px] filled-none ${selected ? 'text-white' : 'text-title'}`}
        />
      </div>
      {number % 2 !== 0 && <div className='text-[12px] text-title mt-[10px]'>{text(number)}</div>}
    </div>
  )
}

const icon = number => {
  switch (number) {
    case 1:
      return faFaceAngry
    case 2:
      return faFaceFrown
    case 3:
      return faFaceMeh
    case 4:
      return faSmileBeam
    case 5:
      return faLaughBeam
  }
}

const text = number => {
  switch (number) {
    case 1:
      return 'Muy malo'
    case 3:
      return 'Correcto'
    case 5:
      return 'Excelente'
    default:
      return ''
  }
}
