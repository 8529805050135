import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { uri } from '../../config/Api'
import debounce from 'lodash.debounce'
import LocationInput from '../LocationInput'
import SlideModal from '../SlideModal'
import { SURVEY_LOCATIONS } from '../../config/Places'
import { useAuth, useAuthorizedFetch } from '../../hooks'

const AutocompleteBox = ({
  placeholder,
  setLocation,
  setLocationName,
  locationName,
  setText,
  text,
  adornment,
  handleSelect
}) => {
  const { user } = useAuth()
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] = useState(false)
  const [placePredictions, setPlacePredictions] = useState([])
  const [loadingText, setLoadingText] = useState('Cargando')
  const [width, setWidth] = useState(window.innerWidth)
  const [openModal, setOpenModal] = useState(false)
  const [preferredLocations, setPreferredLocations] = useState([])
  const [fetchPreferredLocations, setFetchPreferredLocations] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const onSelected = details => {
    if (details) {
      setLocation({
        lat: details.geometry.location.lat,
        lng: details.geometry.location.lng
      })
      const name = {
        formatted_address: styleAddress(details.formatted_address),
        locality: details.address_components.filter(x => x.types.includes('locality'))[0].long_name,
        country_code: details.address_components.filter(x => x.types.includes('country'))[0]
          .short_name
      }
      setLocationName(name)
      setText(formatAddress(styleAddress(details.formatted_address)))
      if (handleSelect) handleSelect(name)
    }
  }

  const getPlacePredictions = input => {
    fetch(`${uri}/locations?input=${removeAccents(input)}`)
      .then(res => res.json())
      .then(data => {
        setIsPlacePredictionsLoading(false)
        setPlacePredictions(data)
      })
      .catch(err => {
        console.log(err)
        setIsPlacePredictionsLoading(false)
        setPlacePredictions([{ formatted_address: 'Error al buscar ubicaciones' }])
      })
  }

  const debouncedChangeHandler = useCallback(debounce(getPlacePredictions, 500), [])

  const styleAddress = address => {
    //remove numbers
    address = address?.replace(/(\d+)/g, '')
    return address?.trim()
  }

  const removeAccents = str => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  useEffect(() => {
    if (isPlacePredictionsLoading) {
      const interval = setInterval(() => {
        setLoadingText(prev => {
          if (prev.length > 10) {
            return 'Cargando'
          }
          return prev + '.'
        })
      }, 250)
      return () => clearInterval(interval)
    }
  }, [isPlacePredictionsLoading])

  const handleBack = (location, locationName) => {
    if (location && locationName) {
      setLocation(location)
      setLocationName(locationName)
      setText(formatAddress(locationName.formatted_address))
      if (handleSelect) handleSelect(locationName)
    }
    setOpenModal(false)
  }

  useEffect(() => {
    const locations = localStorage.getItem('preferred_locations')
    if (locations) {
      setPreferredLocations(SURVEY_LOCATIONS.filter(location => locations.includes(location.label)))
    } else if (user?.sub) {
      setFetchPreferredLocations(true)
    }
  }, [user, fetchPreferredLocations])

  return (
    <>
      <div className='relative'>
        {width < 640 ? (
          <>
            <div
              className={`bg-white border-[1px] border-gray w-full h-14 rounded-[12px] flex items-center px-[16px] text-sm ${
                locationName?.formatted_address ? 'text-black' : 'text-gray'
              }`}
              onClick={() => setOpenModal(true)}
            >
              {locationName?.formatted_address
                ? formatAddress(locationName.formatted_address)
                : placeholder}
            </div>
            {adornment}
            {fetchPreferredLocations && (
              <PreferredLocations
                userId={String(user?.sub)}
                setFetch={setFetchPreferredLocations}
              />
            )}
          </>
        ) : (
          <Autocomplete
            filterOptions={x => x} // desabilita q automaticamente filtre en los que hay
            freeSolo={!(text != '' && placePredictions.length == 0 && !isPlacePredictionsLoading)}
            fullWidth
            onKeyDown={evt => {
              //Evita que haga algo al presionar enter
              if (evt.key === 'Enter') {
                evt.defaultMuiPrevented = true
              }
            }}
            onInputChange={evt => {
              if (evt?.target && evt.target.value != 0) {
                setText(evt.target.value)
                setIsPlacePredictionsLoading(true)
                debouncedChangeHandler(evt.target.value.trim())
              } else if (evt?.target?.value == '') {
                setText(evt.target.value)
              }
            }}
            onChange={(evt, value) => {
              if (value) {
                onSelected(value)
                setPlacePredictions([])
              }
            }}
            disableClearable={true}
            inputValue={text}
            options={placePredictions}
            getOptionLabel={option => styleAddress(option.formatted_address)}
            loading={isPlacePredictionsLoading}
            loadingText={loadingText}
            noOptionsText='No hay resultados, prueba con un departamento'
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                label={placeholder}
                sx={{ fontFamily: 'Poppins, sans-serif' }}
                type='text'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: null
                }}
              />
            )}
          />
        )}
        {adornment}
      </div>
      <SlideModal open={openModal} onClose={handleBack}>
        <div className='flex flex-col h-[95vh] overflow-auto pr-3 pl-3 text-2xl pt-6 text-xl'>
          <LocationInput
            placeholder={placeholder}
            setText={setText}
            text={text}
            handleBack={handleBack}
            preferredLocations={preferredLocations}
          />
        </div>
      </SlideModal>
    </>
  )
}

const formatAddress = address => {
  const formattedAddress = address?.split(',')
  if (address?.match(/Uruguay$/)) {
    return formattedAddress[0]
  } else {
    return formattedAddress[0] + ', ' + formattedAddress[2]
  }
}

const PreferredLocations = ({ userId, setFetch }) => {
  const { loading, response } = useAuthorizedFetch(
    `${uri}/users/id/${userId}?fields=preferred_locations`
  )

  useEffect(() => {
    if (response.ok) {
      response.json().then(async data => {
        if (data.preferred_locations)
          localStorage.setItem('preferred_locations', data.preferred_locations)
        setFetch(false)
      })
    }
  }, [response])

  return null
}

export default AutocompleteBox
