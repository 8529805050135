import { TextField } from '@mui/material'
import NextButton from './NextButton'

export default ({ referral, setReferral, handleSubmit }) => {
  return (
    <div className='flex flex-col px-6 text-2xl mt-6 overflow-auto sm:items-center'>
      <div className='sm:w-1/2'>
        <p className='text-start mb-6'>¿Sos un conductor referido?</p>
        <TextField
          id='referral'
          onChange={evt => setReferral(evt.target.value)}
          placeholder='Código de referido'
          sx={{ width: '100%' }}
          value={referral}
          variant='filled'
          InputProps={{
            disableUnderline: true,
            style: { borderRadius: 10, paddingTop: 10, paddingBottom: 10 }
          }}
          inputProps={{
            maxLength: 30,
            style: {
              fontFamily: 'Poppins, sans-serif',
              fontWeight: 500,
              paddingTop: 8,
              textTransform: referral.trim().length > 0 ? 'uppercase' : 'none'
            }
          }}
        />
        <p className='text-start text-gray text-base mt-6 ml-1'>
          Si un usuario te envió su código de referido, utilizalo para que ambos puedan recibir
          beneficios.
        </p>
        <NextButton
          onSubmit={() => {
            handleSubmit(referral.toUpperCase())
          }}
          disabled={referral.trim().length < 5}
        >
          <button
            onClick={() => {
              setReferral('')
              handleSubmit(null)
            }}
            className='text-lg text-light_coral'
          >
            Omitir
          </button>
        </NextButton>
      </div>
    </div>
  )
}
