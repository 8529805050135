module.exports = {
  SURVEY_LOCATIONS: [
    {
      label: 'Montevideo',
      formatted_address: 'Montevideo, Departamento de Montevideo, Uruguay',
      address_components: [
        { long_name: 'Montevideo', short_name: 'Montevideo', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.9011127, lng: -56.16453139999999 } }
    },
    {
      label: 'Canelones',
      formatted_address: 'Ciudad de la Costa, Departamento de Canelones, Uruguay',
      address_components: [
        {
          long_name: 'Ciudad de la Costa',
          short_name: 'Ciudad de la Costa',
          types: ['locality', 'political']
        },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.8274342, lng: -55.9684244 } }
    },
    {
      label: 'Maldonado',
      formatted_address: 'Maldonado, Departamento de Maldonado, Uruguay',
      address_components: [
        { long_name: 'Maldonado', short_name: 'Maldonado', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.9018717, lng: -54.9634343 } }
    },
    {
      label: 'Salto',
      formatted_address: 'Salto, Departamento de Salto, Uruguay',
      address_components: [
        { long_name: 'Salto', short_name: 'Salto', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -31.388028, lng: -57.9612455 } }
    },
    {
      label: 'Paysandú',
      formatted_address: 'Paysandú, Departamento de Paysandú, Uruguay',
      address_components: [
        { long_name: 'Paysandú', short_name: 'Paysandú', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -32.3112903, lng: -58.0757701 } }
    },
    {
      label: 'Colonia',
      formatted_address: 'Colonia del Sacramento, Departamento de Colonia, Uruguay',
      address_components: [
        {
          long_name: 'Colonia del Sacramento',
          short_name: 'Col. del Sacramento',
          types: ['locality', 'political']
        },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.4607189, lng: -57.83390989999999 } }
    },
    {
      label: 'Tacuarembó',
      formatted_address: 'Tacuarembó, Departamento de Tacuarembó, Uruguay',
      address_components: [
        { long_name: 'Tacuarembó', short_name: 'Tacuarembó', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -31.7206837, lng: -55.9859887 } }
    },
    {
      label: 'Artigas',
      formatted_address: 'Artigas, Departamento de Artigas, Uruguay',
      address_components: [
        { long_name: 'Artigas', short_name: 'Artigas', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -30.4068179, lng: -56.4775937 } }
    },
    {
      label: 'Rocha',
      formatted_address: 'Rocha, Departamento de Rocha, Uruguay',
      address_components: [
        { long_name: 'Rocha', short_name: 'Rocha', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.4809769, lng: -54.3307438 } }
    },
    {
      label: 'Rivera',
      formatted_address: 'Rivera, Departamento de Rivera, Uruguay',
      address_components: [
        { long_name: 'Rivera', short_name: 'Rivera', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -30.9178625, lng: -55.5469032 } }
    },
    {
      label: 'San José',
      formatted_address: 'San José, Departamento de San José, Uruguay',
      address_components: [
        { long_name: 'San José', short_name: 'San José', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.3486042, lng: -56.7075141 } }
    },
    {
      label: 'Durazno',
      formatted_address: 'Durazno, Departamento de Durazno, Uruguay',
      address_components: [
        { long_name: 'Durazno', short_name: 'Durazno', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -33.3822982, lng: -56.5275506 } }
    },
    {
      label: 'Flores',
      formatted_address: 'Trinidad, Departamento de Flores, Uruguay',
      address_components: [
        { long_name: 'Trinidad', short_name: 'Trinidad', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -33.5201418, lng: -56.9042201 } }
    },
    {
      label: 'Cerro Largo',
      formatted_address: 'Melo, Departamento de Cerro Largo, Uruguay',
      address_components: [
        { long_name: 'Melo', short_name: 'Melo', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -32.3648617, lng: -54.1665506 } }
    },
    {
      label: 'Florida',
      formatted_address: 'Florida, Departamento de Florida, Uruguay',
      address_components: [
        { long_name: 'Florida', short_name: 'Florida', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.0948167, lng: -56.2202946 } }
    },
    {
      label: 'Lavalleja',
      formatted_address: 'Minas, Departamento de Lavalleja, Uruguay',
      address_components: [
        { long_name: 'Minas', short_name: 'Minas', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.3643905, lng: -55.2590707 } }
    },
    {
      label: 'Río Negro',
      formatted_address: 'Fray Bentos, Departamento de Río Negro, Uruguay',
      address_components: [
        { long_name: 'Fray Bentos', short_name: 'Fray Bentos', types: ['locality', 'political'] },

        { long_name: 'Uruguay', short_name: 'UY', types: ['locality', 'political'] }
      ],
      geometry: { location: { lat: -33.1228052, lng: -58.2975552 } }
    },
    {
      label: 'Soriano',
      formatted_address: 'Mercedes, Departamento de Soriano, Uruguay',
      address_components: [
        { long_name: 'Mercedes', short_name: 'Mercedes', types: ['locality', 'political'] },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -33.2604441, lng: -58.0185646 } }
    },
    {
      label: 'Treinta y Tres',
      formatted_address: 'Treinta y Tres, Departamento de Treinta y Tres, Uruguay',
      address_components: [
        {
          long_name: 'Treinta y Tres',
          short_name: 'Treinta y Tres',
          types: ['locality', 'political']
        },
        { long_name: 'Uruguay', short_name: 'UY', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -33.229046, lng: -54.3576473 } }
    },
    {
      label: 'Argentina',
      formatted_address: 'CABA, Provincia de Buenos Aires, Argentina',
      address_components: [
        { long_name: 'Buenos Aires', short_name: 'CABA', types: ['locality', 'political'] },
        { long_name: 'Argentina', short_name: 'AR', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -34.6036844, lng: -58.3815591 } }
    },
    {
      label: 'Brasil',
      formatted_address: 'Porto Alegre, Río Grande del Sur, Brasil',
      address_components: [
        { long_name: 'Porto Alegre', short_name: 'Porto Alegre', types: ['locality', 'political'] },
        { long_name: 'Brasil', short_name: 'BR', types: ['country', 'political'] }
      ],
      geometry: { location: { lat: -30.0368176, lng: -51.2089887 } }
    }
  ]
}
